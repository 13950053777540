import { useEffect, useState } from 'react';
import { Login } from './pages/Login/index'
import { Principal } from './pages/Principal';
import { Helmet } from 'react-helmet';
import ChatWidget from './components/BsChat';
import { BsNotification } from './components/BsNotificacao';
import { useAuth } from './contexts/AuthProvider/useAuth';
import { Pagamento } from './pages/Public/Pagamento';
import { useLocation } from 'react-router-dom';
import { Register } from './pages/Register';

function App() {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if (auth.token !== null) {
      setLoading(false);
    }
  }, [auth.token]);

  function getHelmet() {
    return <Helmet>
      <title>BitSafira - Painel</title>
      <meta name='description' content='Plataforma integrada para gerenciamento eficiente de clientes IPTV, serviços, notificações e cobranças automáticas via WhatsApp. Oferecemos controle completo de assinaturas, mensalidades e aluguel.' />
      <meta name='keywords' content='gestão de clientes, gestor IPTV, gerenciamento Cliente IPTV, cobrança Cliente, cobrança mensalidade assinatura, cobrança aluguel, envio automático, WhatsApp, disparos em massa, hospedagem, desenvolvimento de software, API, integração de software, integração ERP, integração whatsapp, IPTV' />
    </Helmet>
  }

  if (loading) {
    return getHelmet()
  }

  return (
    <>
      {getHelmet()}

      <ChatWidget />
      {
        location.pathname.startsWith("/payment-form") ? <Pagamento /> :
          location.pathname.startsWith("/register") ? <Register /> :
            !auth.token ? <Login /> : <Principal />
      }
      <BsNotification />
    </>
  );
}

export default App;