import { FormControl, TextField, Typography, Autocomplete, CircularProgress, IconButton, Checkbox } from '@mui/material';
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { useMemo, useRef, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { BsCadastroPadrao } from '../../BsCadastroPadrao';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { formatedPrice, formatedTelefone } from '../../../utils';
import styled from 'styled-components';
import { FormHelperTextStyled } from '..';

const MenuText = styled.div`
    font-size: 13px;
`
export const BsSelectField = (props: any) => {
    const auth = useAuth();
    const refCadastro = useRef<any>(null);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<any[]>([]);
    const [fetched, setFetched] = useState(false);
    const loading = open && !fetched && (props.options ? props.options.length === 0 : options.length === 0);
    const edit = props?.model && !props.disableEdit && props.value && !props.options && !props.multiple;

    async function fetchData(refresh?: boolean) {
        await auth.requestGet(props?.model?.apiConsulta).then((response: any) => {
            var _options = response.data.dados;

            if (props.showInativos === undefined)
                _options = _options.filter((item: any) => { return ((item?.ativo === undefined) || (item?.ativo === 1)) });

            if ((props.filterModel) && (props.filterModel.value)) {
                if (props.filterModel.select) {
                    if (Array.isArray(props.filterModel.value))
                        _options = _options.filter((item: any) => { return props.filterModel.value.includes(item[props.filterModel.field].contador) })
                    else
                        _options = _options.filter((item: any) => { return item[props.filterModel.field].contador === props.filterModel.value })
                } else
                    _options = _options.filter((item: any) => { return (item[props.filterModel.field] === props.filterModel.value) })
            }

            // Ordenar com base na existência dos campos: descricao, nome ou texto
            _options.sort((a: any, b: any) => {
                if (a.descricao || b.descricao)
                    return (a.descricao || '').localeCompare(b.descricao || '');
                else if (a.nome || b.nome)
                    return (a.nome || '').localeCompare(b.nome || '');
                else if (a.texto || b.texto)
                    return (a.texto || '').localeCompare(b.texto || '');

                return 0; // Se nenhum dos campos existir, a ordem permanece a mesma
            });

            setOptions(_options)
            setFetched(true);

            if (refresh)
                props.onChange(undefined, response.data.dados.filter((item: any) => { return item.contador === props.value.contador })[0]);
        });
    }

    useMemo(() => {
        if ((!props.options) && (props.model) && (props.model.apiConsulta))
            fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.model]);


    function getDescription(option: any) {
        console.log(option);
        console.log(props.model)
        return ((option.contador && !props.options) ? option.contador + ' - ' : '') +
            (option.descricao ? option.descricao : option.nome ? option.nome : option.texto ? option.texto : '') +
            (option?.tipoServico?.descricao ? ' - ' + option?.tipoServico?.descricao : '') +
            (option.valor ? ' - ' + formatedPrice(option.valor) : '') +
            (option.whatsapp ? ' - ' + formatedTelefone(option.whatsapp).replace('+', '') : '') +
            (option && props.model && props.model.apiConsulta === 'servidoresSafiraway' ? ' | Qtde. Disponível: ' + (option?.quantidadeTotal - option?.quantidadeUso) : '')
    }

    return (
        <>
            <FormControl fullWidth>
                <Autocomplete
                    id={props.id}
                    value={props.value ? props.value : ''}
                    disableClearable={true}
                    disabled={props.disabled}
                    onChange={props.onChange}
                    loading={loading}
                    open={open}
                    size={props.size ? props.size : 'small'}
                    multiple={props.multiple}
                    disableCloseOnSelect={props.multiple}
                    getOptionLabel={(option) => getDescription(option)}
                    isOptionEqualToValue={(option, value) => value === undefined || value === '' || option?.contador === value?.contador}
                    options={props.options ? props.options : options ? options : []}
                    renderOption={(propsRender: any, option: any, { selected }) => {
                        return (
                            <li {...propsRender} style={{ fontSize: 12 }}>
                                {
                                    props.multiple &&
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ margin: 0, marginRight: 8, padding: 0 }}
                                        checked={selected}
                                    />
                                }
                                {getDescription(option)}
                            </li>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={props.label}
                            required={props.required}
                            error={props.submitted && props.required && !props.disabled && props.value === ''}
                            InputLabelProps={{ required: props.required, style: { fontSize: 13 }, }}
                            InputProps={{
                                ...params.InputProps,
                                style: { fontSize: 14 },
                                endAdornment: (
                                    <>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {edit ?
                                            <IconButton size='small' onClick={() => refCadastro.current.openModal()}>
                                                <EditIcon sx={{ width: 16, height: 16 }} />
                                            </IconButton>
                                            : null}
                                        {edit ?
                                            <IconButton size='small' onClick={() => props.clearValue()}>
                                                <ClearIcon sx={{ width: 18, height: 18 }} />
                                            </IconButton>
                                            : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    openText='Expandir'
                    closeText='Retrair'
                    loadingText={<MenuText>Buscando Registros...</MenuText>}
                    noOptionsText={<MenuText>Nenhum registro encontrado</MenuText>}
                />
                <FormHelperTextStyled sx={{ ml: 0, mt: 0 }} >
                    {props.submitted && props.required && !props.disabled && props.value === '' &&
                        <Typography variant='caption' sx={{ alignSelf: 'flex-start', fontSize: 11 }} color={'error'}>
                            {'Campo obrigatório'}
                        </Typography>}
                </FormHelperTextStyled>
            </FormControl >
            {props.model && props.model.apiConsulta === 'servidoresSafiraway' && props.value.dominio &&
                <div style={{ alignSelf: 'flex-start', fontSize: 11 }}>
                    Antes de salvar, configure o registro CNAME do seu domínio para apontar para "{props.value.dominio}".
                </div>}
            {edit && 
                <BsCadastroPadrao
                    ref={refCadastro}
                    register={props.value}
                    model={props.model}
                    title={'Editar - ' + props.model.title}
                    prefixApiCadastro={props.model.apiCadastro}
                    onAfterConfirm={() => {
                        setOptions([]);
                        fetchData(true);
                    }}
                />
            }
        </>
    )
}