import { Grid } from "@mui/material";
import React, { useImperativeHandle, useRef, useState } from "react";
import { toast } from 'react-toastify';
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { HORA_SERVIDOR, RENOVAR_CLIENTE } from "../../../routes_api";
import { useParams } from "../../../contexts/ParamsProvider/useParameters";
import { formatedPrice, MobVersion, newDateExpiracao, showError } from "../../../utils";
import { MonetaryInput } from "../../../new_components/MonetaryInput";
import { Input } from "../../../new_components/Input";
import EventIcon from '@mui/icons-material/Event';
import { Modal } from "../../../new_components/Modal";
import { Div100, DivCenter, Divider, DivSpaceBetween, Texto, Titulo } from "../../../styledcomponents";
import { CheckBox } from "../../../new_components/CheckBox";

const InputGroup = (props: any) => {
    return (
        <DivSpaceBetween style={{ margin: '15px 0px' }}>
            <Div100>{props.title}</Div100>
            <div>{props.children}</div>
        </DivSpaceBetween>
    )
}

export const RenovarCliente = React.forwardRef((props: any, ref) => {
    const auth = useAuth();
    const params = useParams();

    const [dados, setDados] = useState<any>();

    const refmodal = useRef<any>();
    const refqtde = useRef<any>();
    const refservicos = useRef<any>();
    const refdesconto = useRef<any>();
    const refbonus = useRef<any>();
    const reftotal = useRef<any>();
    const refexpiracao = useRef<any>();
    const refrecibo = useRef<any>();

    useImperativeHandle(ref, () => ({ openModal }));

    const getDateServer = async () => {
        try {
            const response: any = await auth.requestGet(HORA_SERVIDOR);
            return response.data.dados;
        } catch {
            return new Date();
        }
    };

    async function calculaNovaExpiracao(_meses: any, _expiracao: any) {
        let date_server = await getDateServer();
        let date_expiracao: any = await newDateExpiracao(date_server, _expiracao, params.dataAtualRenovacao, _meses ?? 1);

        refexpiracao.current.setValue(date_expiracao);
    }

    function calculaTotal(qtde: any, desconto: any, bonus: any) {
        let _total = qtde * dados.servico.valor - desconto - bonus;

        refservicos.current.setValue(qtde * dados.servico.valor);
        reftotal.current.setValue(_total);
    }

    function changeQtde(_value: any) {
        calculaTotal(_value, refdesconto.current.getValue() ?? 0, refbonus?.current?.getValue() ?? 0);
        calculaNovaExpiracao(_value, dados.cliente.expiracao);
    }

    function changeDesconto(_value: any) {
        calculaTotal(refqtde.current.getValue() ?? 0, _value, refbonus?.current?.getValue() ?? 0);
    }

    function changeBonus(_value: any) {
        calculaTotal(refqtde.current.getValue() ?? 0, refdesconto.current.getValue() ?? 0, _value);
    }

    async function openModal(_contador: any, _cliente: any, _servico: any) {
        await setDados({
            contador: _contador,
            cliente: _cliente,
            servico: _servico
        });

        refmodal.current.openModal();
        calculaNovaExpiracao(1, _cliente.expiracao);
    }

    async function validate(): Promise<boolean> {
        let _totalReceber = reftotal.current.getValue();

        const _dataServer = Number(new Date(await getDateServer()));
        const _dataExpiracaoAtual = Number(new Date(Date.parse(String(dados.cliente.expiracao))));
        const _dataNovaExpiracao = Number(new Date(refexpiracao.current.getValue()));

        if (!_dataNovaExpiracao) {
            toast.error('"Nova Data Expiração" não informada.');
            return false;
        }

        if (_dataServer > _dataNovaExpiracao) {
            toast.error('"Nova Data Expiração" é menor que a data atual.');
            return false;
        }

        if (_dataExpiracaoAtual > _dataNovaExpiracao) {
            toast.error('"Nova Data Expiração" deve ser maior que a "Expiração Atual".');
            return false;
        }

        if (_totalReceber < 0) {
            toast.error('"Total Receber" precisa ser maior ou igual a zero.');
            return false;
        }

        return true;
    }

    async function renovar() {
        async function execute() {
            try {
                refmodal.current.setLoading(true);

                const body = {
                    contador: dados.contador,
                    cliente: { contador: dados.cliente.codigo },
                    quantidade: Number(refqtde.current.getValue()),
                    dataExpiracao: refexpiracao.current.getValue(),
                    bonusUtilizado: refbonus?.current?.getValue() ?? 0,
                    desconto: refdesconto.current.getValue() ?? 0,
                    valorRecebido: reftotal.current.getValue() ?? 0,
                    enviarRecibo: refrecibo.current.getValue() ?? 1
                };

                const response: any = await auth.requestPost(RENOVAR_CLIENTE, body);
                refmodal.current.closeModal();
                toast.success(response.data.mensagem);
                props.onFinish(true);
            } catch (error) {
                refmodal.current.setLoading(false);
                showError(error);
            }
        }

        if (await validate())
            execute();
    }

    return (
        <Modal ref={refmodal} title='Renovação de Cliente' confirmClick={() => renovar()}>
            {dados?.cliente &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div style={{ padding: '20px 10px 0px' }}>
                            <DivCenter>
                                <EventIcon style={{ fontSize: '80px', marginRight: '2rem' }} />
                                <div>
                                    <Titulo>{dados.cliente.nome}</Titulo>
                                    <Texto>{dados.servico.descricao + ' - ' + formatedPrice(dados.servico.valor)}</Texto>
                                    {(dados.cliente.bonus > 0) && <Texto style={{ fontWeight: 600, color: '#098b4a' }}>{'Bônus Disponível: ' + formatedPrice(dados.cliente.bonus)}</Texto>}
                                </div>
                                <DivCenter />
                            </DivCenter>

                            <Divider />

                            <InputGroup title={'RENOVAÇÕES'}>
                                <Input ref={refqtde} id={'qtderenovacoes'} type={'number'} defaultValue={1} simbolstart={'×'} onAfterChange={changeQtde} />
                            </InputGroup>
                            <InputGroup title={'TOTAL SERVIÇOS'}>
                                <MonetaryInput ref={refservicos} id="totalservicos" disabled value={dados.servico.valor} simbolstart={'='} />
                            </InputGroup>
                            <InputGroup title={'DESCONTO'}>
                                <MonetaryInput ref={refdesconto} id="desconto" simbolstart={'-'} onAfterChange={changeDesconto} />
                            </InputGroup>
                            {(dados.cliente.bonus > 0) &&
                                <InputGroup title={'BÔNUS'}>
                                    <MonetaryInput ref={refbonus} id="bonus" simbolstart={'-'} onAfterChange={changeBonus} />
                                </InputGroup>
                            }
                            <InputGroup title={'TOTAL RECEBER'}>
                                <MonetaryInput ref={reftotal} id="totalreceber" disabled value={dados.servico.valor} simbolstart={'='} />
                            </InputGroup>

                            <Divider />

                            <DivSpaceBetween style={{ display: MobVersion() ? 'block' : 'flex', marginTop: '20px' }}>
                                <div className="p-2">
                                    <Input id={'expiracaoatual'} type={'datetime-local'} label={'Expiração Atual'} defaultValue={dados.cliente.expiracao} disabled />
                                </div>

                                <div className="p-2">
                                    <Input ref={refexpiracao} id={'novaexpiracao'} type={'datetime-local'} label={'Nova Data Expiração'} required />
                                </div>
                            </DivSpaceBetween>
                            <CheckBox ref={refrecibo} id="recibo" label={"Enviar Recibo"} defaultValue={1} inputstyle={{ justifyContent: 'flex-end' }} />
                        </div>
                    </Grid>
                </Grid>
            }
        </Modal>
    )
})