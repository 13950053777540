import { BsColumns, BsTelaPadrao } from "../../../components/BsTelaPadrao";
import { model_safiraway } from "../../../models";
import { intToAtivoInativo, labelAtivoInativo } from "../../../utils";

export const SafiraWay = () => {
    const cols: Array<BsColumns> = [
        { field: 'contador', header: 'Código', sortable: true, style: { width: '100px' } },
        { field: 'descricao', header: 'Descrição', sortable: true },
        { field: 'dominio', header: 'Domínio', sortable: true },
        { field: 'servidor.descricao', header: 'Servidor', sortable: true },
        { field: 'ativo', header: 'Situação', sortable: false, body: (e) => labelAtivoInativo(e.ativo), style: { width: '100px' } },
        { field: 'edit', exportable: false, style: { width: '4rem' } }
    ];

    const onFilter = (value: any, filter: string) => {
        return intToAtivoInativo(value.ativo).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.contador).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.descricao).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.dominio).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.servidor.descricao).toLowerCase().includes(filter.toLowerCase());
    }

    return (
        <BsTelaPadrao
            prefixApiDelete={'/safiraway/excluir'}
            disabledEdit
            disabledInativar
            columns={cols}
            model={model_safiraway}
            onFilterRegisters={onFilter}
        />
    );
}
